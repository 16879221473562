import React from 'react'
import Layout from '../../templates/Main'
import Account from '../../modules/Account'
import Heading from '../../modules/Heading'


const RestorersAccountPage = ({ params }) => {
    return (
        <Layout id={params.id}>
            <Heading size='h1' text='Restorer Account' />
            <Account type='restorer' />
        </Layout>
    )
}

export default RestorersAccountPage
